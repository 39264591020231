/**
 * This contains code that is shared between our internal input component types (TextInput.vue, etc, all rendered through
 * QuestionInput.vue).
 *
 * This code must NEVER contain connection / profile specific code. You will die a thousand deaths if you include any.
 */
import type {RiskOverride} from "@/composables/connections/types";
import {handleXhrError} from "@/composables/errorHandling";
import {RegisteredErrors} from "@/composables/errorHandling/registeredErrors";
import {translate} from "@/composables/i18n";
import {injectStrict} from "@/composables/provideInject";
import {AnswersKey, QuestionTreeDataKey} from "@/composables/questions/injections";
import type {UserOption, UserOptionsGroup} from "@/composables/questions/types";
import {AnswerFormats} from "@/composables/questions/types";
import {getRandomId} from "@/composables/utils";
import {getCurrentUser, getUserLocale} from "@/composables/vuex";
import {httpPost} from "@/composables/xhr";
import globalLogger from "@/logging";
import type {AxiosResponse} from "axios";
import cloneDeep from "lodash/cloneDeep";
import type {MaskInputOptions, MaskOptions, MaskTokens} from "maska";
import {Mask} from "maska";
import type {Answers} from "pg-isomorphic/api/connection";
import {Internal, QuestionTag, QuestionType, SpecialUser} from "pg-isomorphic/enums";
import {ALL_OPTION_VALUE} from "pg-isomorphic/enums/answers";
import {SpecialKeyValues} from "pg-isomorphic/enums/question";
import type {SupportedLocale} from "pg-isomorphic/locales";
import type {BasicOption, QuestionOption} from "pg-isomorphic/options";
import {isLocaleObjectQuestion, processTreeTopDown} from "pg-isomorphic/profile";
import {find as findQuestions} from "pg-isomorphic/profile/index";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {advancedIsUnanswered, coerceToArray, isEmptyOrUndefined} from "pg-isomorphic/utils";
import {getTextFromLocaleObj, isLocaleObj, isShortLocaleException, shortLocale} from "pg-isomorphic/utils/locale";
import type {Dictionary} from "ramda";
import {any, equals, isEmpty, isNil, last, path, pathOr, pluck, sortBy, type, without} from "ramda";
import type {ComputedRef, Ref} from "vue";
import {computed, inject, ref} from "vue";

const logger = globalLogger.getLogger("composable.inputs");

export const UNIQUE_TAGS = [QuestionTag.UNIQUE_TAX_ID];

/**
 * Helper method to get a computed ref of a value within questionData
 *
 * @param questionData
 * @param dataKey
 * @param defaultValue
 */
export function getQuestionDataComputed<T>(
  questionData: Ref<JSONQuestion>,
  dataKey: string,
  defaultValue: T,
): ComputedRef<T> {
  return computed(() => pathOr(defaultValue, dataKey.split("."), questionData.value) as T);
}

/**
 * Gets the value, not a computed ref, of a question data config
 *
 * @param questionData
 * @param dataKey
 * @param defaultValue
 */
export function getQuestionDataValueOnly<T>(questionData: JSONQuestion, dataKey: string, defaultValue: T): T {
  return pathOr(defaultValue, dataKey.split("."), questionData) as T;
}

/**
 * Gets a specific component option, which is an option that is separate form the regular options within question data. Usually
 * these options will be UI only.
 *
 * You can either pass component options through question data (under "componentOptions") or provide the option for a more global
 * way (as used by ElementInputVuex.vue for example)
 *
 * @param questionData
 * @param dataKey
 * @param defaultValue
 */
export function getComponentOptionComputed<T>(
  questionData: JSONQuestion,
  dataKey: string,
  defaultValue: T,
): ComputedRef<T> {
  const ComponentOptionInjection: T | undefined = inject(`input:${dataKey}`);
  return computed(() => {
    return (
      ComponentOptionInjection || (pathOr(defaultValue, ["componentOptions", ...dataKey.split(".")], questionData) as T)
    );
  });
}

export function getIsAnsweredComputed(answerValue: Ref<any>, type: Ref<string>) {
  return computed(() => {
    return !advancedIsUnanswered(answerValue.value, type.value);
  });
}

/**
 * Creates a unique value for the id of a input.
 * @param questionData
 */
export function getUniqueInputId(questionData: JSONQuestion) {
  const uid = getRandomId(); // to avoid auto-fill because autocomplete="off" doesn't always work
  if (questionData.key) {
    return `${questionData.key}-${uid}`;
  } else {
    const label = questionData.label || "";
    return `underline-input-${label.replace(/\W/g, "-")}-${uid}`;
  }
}

/**
 * Format an id by replacing "invalid" characters with underscores
 * @param id
 */
export function formatId(id: string) {
  return id.replace(/\W/, "_");
}

/**
 * Creates a unique value for the id of a input.
 * @param questionData
 */
export function getQuestionElementId(questionData: JSONQuestion) {
  return formatId(`element_${questionData._id || questionData.key}`);
}

/**
 * The expected language input, this probably only applies to text based fields
 *
 * @param questionData
 */
export function expectedLanguageComputed(questionData: Ref<JSONQuestion>) {
  const userLocale = getUserLocale();
  return computed(() => {
    const expectedLocaleLang = pathOr("", ["expectedLocaleLanguage"], questionData.value);
    if (expectedLocaleLang === "USER") {
      return [shortLocale(userLocale)];
    } else if (expectedLocaleLang !== "") {
      const splitLanguages = expectedLocaleLang.split(",");
      return splitLanguages.map((l: string) => shortLocale(l));
    }
    return expectedLocaleLang;
  });
}

/**
 * Returns the answer from a given questionData config
 *
 * @param questionData
 */
export function getAnswerFormatFromQuestionData(questionData: JSONQuestion) {
  switch (questionData.type) {
    case QuestionType.TEXT:
    case QuestionType.TEXTAREA:
    case QuestionType.EMAIL:
      if (isLocaleObjectQuestion(questionData)) {
        return AnswerFormats.TEXT_LOCALE;
      } else {
        return AnswerFormats.TEXT;
      }
    case QuestionType.NUMBER:
      return AnswerFormats.NUMBER;
    case QuestionType.MULTI_LANGUAGE_TEXT:
      return AnswerFormats.TEXT_LOCALE;
    case QuestionType.SELECT:
    case QuestionType.USER_SELECT:
    case QuestionType.CHECKBOX:
    case QuestionType.CHECKBOX_OTHER:
    case QuestionType.CHECKLIST_VIEW:
    case QuestionType.RADIO:
    case QuestionType.RADIO_OTHER:
    case QuestionType.TWO_LIST_MULTI_SELECT:
    case QuestionType.USER_SELECT_EMAIL:
      return AnswerFormats.SELECTED_OPTIONS;
    case QuestionType.TAGS:
      return AnswerFormats.TAGS;
    case QuestionType.DATE:
    case QuestionType.DATE_TIME:
      return AnswerFormats.DATE;
    case QuestionType.BOOLEAN:
      return AnswerFormats.BOOLEAN;
    case QuestionType.CERTIFY: //  This probably should be none, since it's an answer format that is completely unique (added none after this)
      return AnswerFormats.CERTIFY;
    case QuestionType.ADDRESS:
      return AnswerFormats.ADDRESS;
    case QuestionType.ROUTING:
      return AnswerFormats.ROUTING;
    case QuestionType.SAM_GOV:
      return AnswerFormats.SAM_GOV;
    case QuestionType.IMAGE:
    case QuestionType.FILE:
    case QuestionType.FILE_OR_URL:
    case QuestionType.URL:
    case QuestionType.DIVIDER:
    case QuestionType.INFO:
    case QuestionType.STOP:
    case QuestionType.WARNING:
    case QuestionType.OBSCURED:
    case QuestionType.ROLE_TABLE:
    case QuestionType.BUTTON:
    case QuestionType.KIT_ENTITY_TABLE:
    case QuestionType.POINTER:
    case QuestionType.MASTER_DATA_CONNECTIONS_TABLE:
      return AnswerFormats.NONE;
    case QuestionType.CONTACT:
      return AnswerFormats.CONTACT;
    case QuestionType.ASSOCIATED_KIT_TABLE:
      return AnswerFormats.ASSOCIATED_KIT_TABLE;
    case QuestionType.MULTI_TEXT:
      return AnswerFormats.ARRAY_OF_STRINGS;
    default:
      return AnswerFormats.TEXT;
  }
}

export function isNoAnswerType(questionData: JSONQuestion, answer: any) {
  const inputType = questionData.type;
  if (inputType !== QuestionType.IMAGE) {
    if ((inputType === QuestionType.TEXT || inputType === QuestionType.TEXTAREA) && isLocaleObj(answer)) {
      const localeValue = getTextFromLocaleObj(answer);
      return isEmpty(localeValue) || isNil(localeValue);
    } else if (inputType === QuestionType.TEXT && Array.isArray(answer)) {
      return answer.filter((elem) => !!elem).length === 0;
    } else if (type(answer) === "Array" && inputType !== QuestionType.CONTACT) {
      return answer.length === 0;
    } else {
      return advancedIsUnanswered(
        answer,
        inputType,
        inputType === QuestionType.MULTI_LANGUAGE_TEXT ? questionData.requiredLanguages : null,
        questionData.requiredSubparts,
      );
    }
  }
  return false;
}

export function showReadOnlyViewWithinEdit(questionData: JSONQuestion, riskOverride?: RiskOverride) {
  return (
    ![
      QuestionType.RADIO,
      QuestionType.RADIO_OTHER,
      QuestionType.CHECKBOX,
      QuestionType.CHECKBOX_OTHER,
      QuestionType.CHECKLIST_VIEW,
    ].includes(questionData.type as QuestionType) ||
    riskOverride ||
    questionData.riskAssessment
  );
}

/**
 * Gives the appropriate view component for a given answer format
 *
 * @param answerFormat
 */
export function getViewTypeFromAnswerFormat(answerFormat: AnswerFormats) {
  switch (answerFormat) {
    case AnswerFormats.TEXT:
      return "view-string-answer";
    case AnswerFormats.TEXT_LOCALE:
      return "view-locale-object-answer";
    case AnswerFormats.NUMBER:
      return "view-string-answer";
    case AnswerFormats.SELECTED_OPTIONS:
      return "view-selected-options-answer";
    case AnswerFormats.CONTACT:
      return "view-selected-contacts-answer";
    case AnswerFormats.ARRAY_OF_STRINGS:
      return "view-array-of-strings-answer";
    case AnswerFormats.TAGS:
      return "view-tags-answer";
    case AnswerFormats.DATE:
      return "view-date-answer";
    case AnswerFormats.BOOLEAN:
      return "view-boolean-answer";
    case AnswerFormats.CERTIFY:
      return "view-certify-answer";
    case AnswerFormats.ADDRESS:
      return "view-address-answer";
    case AnswerFormats.ROUTING:
      return "view-routing-answer";
    case AnswerFormats.SAM_GOV:
      return "view-sam-gov-answer";
    case AnswerFormats.ASSOCIATED_KIT_TABLE:
      return "AssociatedKitsTable";
    case AnswerFormats.FILE_OBJECT:
    case AnswerFormats.NONE:
      return "no-view-input"; // Just filler, should never be used
  }
}

export function getViewTypeWithNoAnswer(
  answerFormat: Ref<AnswerFormats>,
  questionData: Ref<JSONQuestion>,
  answer: Ref<any>,
  isNoAnswerTypeResult?: Ref<boolean>,
) {
  return computed(() => {
    if (
      questionData.value.type === QuestionType.MULTI_LANGUAGE_TEXT ||
      (questionData.value.type === QuestionType.CONTACT && !isEmptyOrUndefined(answer.value))
    ) {
      return getViewTypeFromAnswerFormat(answerFormat.value);
    }
    if (isNoAnswerTypeResult !== undefined) {
      return isNoAnswerTypeResult.value ? "no-answer" : getViewTypeFromAnswerFormat(answerFormat.value);
    }
    return isNoAnswerType(questionData.value, answer.value)
      ? "no-answer"
      : getViewTypeFromAnswerFormat(answerFormat.value);
  });
}

/**
 * Returns the appropriate input type for a given questionData config
 *
 * @param questionData
 */
export function getInputTypeComponentFromQuestionData(questionData: JSONQuestion) {
  switch (questionData.type) {
    case QuestionType.TEXT:
    case QuestionType.TEXTAREA:
    case QuestionType.EMAIL:
      return "TextInput";
    case QuestionType.MULTI_TEXT:
      return "MultiTextInput";
    case QuestionType.NUMBER:
      return "number-input";
    case QuestionType.MULTI_LANGUAGE_TEXT:
      return "multi-language-text-input";
    case QuestionType.SELECT:
      return "select-input";
    case QuestionType.USER_SELECT:
    case QuestionType.USER_SELECT_EMAIL:
      return "user-select-input";
    case QuestionType.CHECKBOX:
    case QuestionType.CHECKBOX_OTHER:
    case QuestionType.CHECKLIST_VIEW:
      return "checkbox-input";
    case QuestionType.RADIO:
    case QuestionType.RADIO_OTHER:
      return "radio-input";
    case QuestionType.TAGS:
      return "tags-input";
    case QuestionType.DATE:
    case QuestionType.DATE_TIME:
      return "date-picker-input";
    case QuestionType.BOOLEAN:
      return "boolean-input";
    case QuestionType.ADDRESS:
      return "address-input";
    case QuestionType.ROUTING:
      return "routing-input";
    case QuestionType.SAM_GOV:
      return "sam-gov-input";
    case QuestionType.CERTIFY:
      return "certify-input";
    case QuestionType.FILE:
    case QuestionType.FILE_OR_URL:
    case QuestionType.URL:
      return "file-input";
    case QuestionType.IMAGE:
      return "image-input";
    case QuestionType.ROLE_TABLE:
      return "role-table-input";
    case QuestionType.DIVIDER:
      return "divider-input";
    case QuestionType.INFO:
      return "info-input";
    case QuestionType.OBSCURED:
      return "obscured-input";
    case QuestionType.STOP:
      return "stop-message-input";
    case QuestionType.TWO_LIST_MULTI_SELECT:
      return "two-list-multi-select-input";
    case QuestionType.WARNING:
      return "warning-input";
    case QuestionType.BUTTON:
      return "button-input";
    case QuestionType.KIT_ENTITY_TABLE:
      return "kit-entity-table";
    case QuestionType.CONTACT:
      return "contact-input";
    case QuestionType.ASSOCIATED_KIT_TABLE:
      return "AssociatedKitsTable";
    case QuestionType.MASTER_DATA_CONNECTIONS_TABLE:
      return "BankConnectionSharedTable";
    default:
      return questionData.type;
  }
}

export function getBasicValue(questionData: JSONQuestion) {
  const answerFormat = getAnswerFormatFromQuestionData(questionData);
  if (answerFormat === AnswerFormats.SELECTED_OPTIONS) {
    if (questionData.type === QuestionType.RADIO) {
      if (questionData.options) {
        return questionData.options[0].value;
      } else {
        return null;
      }
    } else if (questionData.options) {
      return [questionData.options[0].value];
    }
    return [questionData.type];
  } else if (answerFormat === AnswerFormats.BOOLEAN) {
    return false;
  } else if (answerFormat === AnswerFormats.TEXT_LOCALE) {
    return {
      __answer_locale: "en-US",
      "en-US": {
        date: new Date(),
        value: questionData.type,
      },
    };
  } else if (answerFormat === AnswerFormats.TEXT) {
    return questionData.type;
  } else {
    return null;
  }
}

export function isPreviewOnlyComponent(questionType: QuestionType) {
  return (
    [
      QuestionType.ADDRESS,
      QuestionType.WARNING,
      QuestionType.ROUTING,
      QuestionType.REFERENCE_TABLE_GIG,
      QuestionType.REFERENCE_TABLE,
      QuestionType.REFERENCE_TABLE_WITHOUT_SELECTION,
      QuestionType.COLUMN_ROW,
      QuestionType.VISUAL_WORKFLOW,
      QuestionType.DOW_JONES,
      QuestionType.STOP,
    ].indexOf(questionType) !== -1
  );
}

export function notVisibleToAdminComponent(questionType: QuestionType) {
  return [QuestionType.DOW_JONES].indexOf(questionType) !== -1;
}

/**
 * Determine if we should always hide the label for a given question type
 *
 * @param questionData
 */
export function getHideLabelFromQuestionData(questionData: JSONQuestion): boolean {
  switch (questionData.type) {
    case QuestionType.INFO:
    case QuestionType.DIVIDER:
    case QuestionType.STOP:
    case QuestionType.WARNING:
      return true;
    default:
      return false;
  }
}

/**
 * Checks whether an option (used by select, checkbox, etc) is deprecated
 *
 * @param option
 * @param questionValue
 */
export function deprecatedWithoutValue(
  option: QuestionOption | UserOption,
  questionValue: Array<string | boolean | number> | string | boolean | number,
): boolean {
  const val = option.value ?? "";
  return (
    option.deprecated &&
    (isNil(questionValue) || (Array.isArray(questionValue) ? !questionValue.includes(val) : questionValue !== val))
  );
}

/**
 * Gets a specific option (used by select, checkbox, etc) label
 *
 * @param questionData
 * @param selectOptions
 * @param value
 * @param defaultValue
 * @param valueName
 * @param labelName
 */
export function getSelectOptionLabelUsingValue(
  questionData: JSONQuestion,
  selectOptions: QuestionOption[],
  value: any,
  defaultValue = "",
  valueName = "value",
  labelName = "label",
): string {
  if (value === SpecialUser.AUTO_APPROVED) {
    return translate("review.auto_approved");
  }
  if (!selectOptions) {
    return value;
  }
  let result = "";
  for (const selectOption of selectOptions) {
    if (selectOption.group && selectOption.groupOptions.length) {
      // check if we are within a grouped select
      result = getSelectOptionLabelUsingValue(
        questionData,
        selectOption.groupOptions,
        value,
        "", // need to know if we don't find anything
        valueName,
        labelName,
      );
      if (result) {
        return result;
      }
    } else {
      if (selectOption[valueName] === value) {
        return selectOption[labelName] as string;
      }
    }
  }
  if (questionData.other && value === "other") {
    return questionData.other?.label as string;
  }
  if (questionData.optionsAnswerKey && value) {
    return translate("questions.missing_reference");
  }
  return defaultValue; // return blank if we don't have a label
}

export function getGroupableUserSelectOptionLabelUsingValue(
  questionData: JSONQuestion,
  selectOptions: UserOption[] | UserOptionsGroup[],
  value: any,
  valueName = "value",
  labelName = "label",
) {
  if (!selectOptions) {
    return value;
  }

  if (value === SpecialUser.AUTO_APPROVED) {
    return translate("review.auto_approved");
  }

  let groupVal;
  for (const selectOption of selectOptions) {
    if (questionData.groupLabel && selectOption[questionData.groupLabel]) {
      groupVal = getGroupableUserSelectOptionLabelUsingValue(
        questionData,
        selectOption[questionData.groupValues],
        value,
        valueName,
        labelName,
      );
      if (groupVal && !equals(groupVal, value)) {
        return groupVal;
      }
    } else {
      if (selectOption[valueName] === value) {
        return selectOption[labelName];
      }
    }
  }
}

/**
 * Add user's country to top of select options if available
 *
 * @param options
 * @param questionData
 */
export function addCountrySelectOptions<T extends BasicOption, Q extends Pick<JSONQuestion, "list">>(
  options: T[],
  questionData: Q,
): T[] {
  if (
    questionData.list?.key !== SpecialKeyValues.COUNTRIES &&
    questionData.list?.key !== SpecialKeyValues.COUNTRIES_GLOBAL
  ) {
    return options;
  }

  // Clone before mutating.
  const entityCountry = getCurrentUser()?.activeEntityInfo?.country || "US";

  // remove any existing before adding
  options = cloneDeep(options).filter((opt) => opt.value !== null && opt.value !== "global");

  const countryIdx = options.findIndex((opt) => opt.value === entityCountry);
  if (countryIdx > -1) {
    const countryOpt = options[countryIdx];
    options.splice(countryIdx, 1);
    options = options.sort((a, b) =>
      a.label.localeCompare(b.label, undefined, {
        caseFirst: "upper",
        sensitivity: "accent",
      }),
    );
    options.unshift({value: null, label: "----------------------------------"} as T);
    if (questionData.list.key === SpecialKeyValues.COUNTRIES_GLOBAL) {
      options.unshift({value: "global", label: translate("general.global")} as T);
    }
    options.unshift(countryOpt);
  }

  return options;
}

/**
 * Whether a option has a specific option for a given value
 *
 * @param options
 * @param value
 * @param trackBy
 */
export function hasOption(options: QuestionOption[], value: any, trackBy = "value") {
  return options && options.find((o) => o[trackBy] === value);
}

/**
 * Setup for reference to a selected group instance.
 */
export function setupReferencePopupIcon(questionData: Ref<JSONQuestion>, optionsRef: ComputedRef<QuestionOption[]>) {
  const enableReferencePopUp = pathOr(false, ["enableReferencePopUp"], questionData.value);
  const showReferencePopupIcon: boolean =
    !!pathOr(false, ["optionsAnswerKey"], questionData.value) && enableReferencePopUp;

  const questions: Ref<JSONQuestion> = injectStrict(QuestionTreeDataKey, ref(null));
  const answers: Ref<Answers> = injectStrict(AnswersKey, ref(null));

  const optionInstanceUnverified: ComputedRef<Dictionary<boolean>> = computed(() => {
    if (!questionData.value || !showReferencePopupIcon || !optionsRef.value || !questions.value) {
      logger.trace(`setupReferencePopupIcon missing required parameter`);
      return {};
    }

    const groupKey: string | undefined = questionData.value?.optionsAnswerKey;
    logger.trace(`setupReferencePopupIcon running on group: ${groupKey}`);
    const splitGroupKey = groupKey?.split(".");
    if (!splitGroupKey || splitGroupKey.length < 1) {
      logger.trace(`setupReferencePopupIcon got bad groupKey: ${groupKey}`);
      return {};
    }

    const foundReferenceTable = findQuestions((q) => q.key === splitGroupKey[0], questions.value);
    if (!foundReferenceTable) {
      logger.error(`Could not find reference table for key: ${splitGroupKey[0]}`);
      return {};
    }

    const unverified = {};
    for (const option of optionsRef.value) {
      const instanceId: string = option.value as string;
      // logger.trace(`setupReferencePopupIcon running on group: ${groupKey}, instance: ${instanceId}`);
      const instance = findQuestions((e) => e.instance === instanceId, foundReferenceTable);
      if (instance) {
        processTreeTopDown((c: JSONQuestion) => {
          if (c.visible) {
            const searchPath = c.key.split(".");
            searchPath[searchPath.length - 1] = searchPath[searchPath.length - 1] + Internal.UNVERIFIED;
            const answer = path(searchPath, answers.value);
            // logger.trace(`For ${searchPath}, got: ${answer}`);
            if (answer) {
              logger.trace(`setupReferencePopupIcon found unverified answer for ${instanceId}, ${c.key}`);
              unverified[instanceId] = true;
            }
          }
        })(instance);
      }
    }
    logger.trace(`setupReferencePopupIcon returning: ${JSON.stringify(unverified)}`);
    return unverified;
  });
  return {showReferencePopupIcon, optionInstanceUnverified};
}

export function hasLengthValidationRules(questionData: JSONQuestion) {
  const rules = pathOr(false, ["validation", "rules"], questionData);
  const {type, min, max, length} = rules;
  return rules && type === "string" && (min || max || length);
}

/**
 * MULTI TEXT SPECIFIC
 */
export function isRequiredLanguage(locale: string, requiredLanguages: string[] = []) {
  return Array.isArray(requiredLanguages) ? requiredLanguages.indexOf(locale) > -1 : false;
}

export function getNonRequiredLocaleListComputed(
  localeList: Dictionary<SupportedLocale>,
  requiredLanguages: ComputedRef<string[]>,
): ComputedRef<string[]> {
  return computed(() => {
    return sortBy((locale) => {
      return !isRequiredLanguage(locale, requiredLanguages.value || []);
    }, Object.keys(localeList));
  });
}

export function getLanguageTextAnswersFromLocaleListComputed(
  nonRequiredLocaleList: ComputedRef<string[]>,
  multiLanguageValue: ComputedRef<any>,
  requiredLanguages: ComputedRef<string[]>,
) {
  return computed(() => {
    const answers: {[key: string]: string} = {};
    for (const locale of nonRequiredLocaleList.value) {
      if (!isNil(multiLanguageValue.value?.[locale]) || isRequiredLanguage(locale, requiredLanguages.value)) {
        answers[locale] = multiLanguageValue.value?.[locale] ? multiLanguageValue.value?.[locale]?.value : "";
      }
    }
    return answers;
  });
}

export function getLanguageDisplayCodesComputed(nonRequiredLocaleList: ComputedRef<string[]>) {
  return computed(() => {
    const codes: {[key: string]: string} = {};
    for (const locale of nonRequiredLocaleList.value) {
      const parts = locale.split("-");
      codes[locale] = (isShortLocaleException(locale) ? parts[1] : parts[0]).toUpperCase().substr(0, 2);
    }
    return codes;
  });
}

export function getLanguageNames(localeList: Dictionary<SupportedLocale>, useName?: boolean) {
  const results: Dictionary<string> = {};
  for (const locale in localeList) {
    results[locale] = useName ? localeList[locale].name : localeList[locale].displayName;
  }
  return results;
}

export function selectAllOptionsWhenAllSelected(
  currentSelection: string[] | string,
  allOptions: BasicOption[],
  multiple: boolean,
): string[] | string {
  let newSelection: string[] = coerceToArray(currentSelection);
  if (multiple && newSelection.includes(ALL_OPTION_VALUE)) {
    return without([ALL_OPTION_VALUE], pluck("value", allOptions) as string[]);
  } else {
    return currentSelection;
  }
}

export const MASK_SEPARATOR = "@@";
export function getMaskOptions(mask: string | string[]): MaskOptions {
  if (mask) {
    let reversed = false;
    let preProcess;
    let postProcess;
    let tokens: MaskTokens = {
      A: {pattern: /[a-z]/i},
      N: {pattern: /[0-9a-z]/i},
      X: {pattern: /./},
      9: {pattern: /[0-9]/, repeated: true},
      U: {pattern: /[a-z]/i, transform: (v) => v.toLocaleUpperCase()},
      "^": {pattern: /[a-z|0-9]/i, transform: (v) => v.toLocaleUpperCase()},
      "?": {pattern: /[?]/, optional: true},
    };

    if (typeof mask !== "function") {
      // special currency mask that is a total hack
      if (mask === "9,99#" || mask === "9,99#?") {
        mask = "0.99";
        tokens = {
          0: {pattern: /\d/, multiple: true},
          9: {pattern: /\d/, optional: true},
        };
        preProcess = (val) => val.replace(/[$,]/g, "");
        postProcess = (val) => {
          if (!val) return "";

          const sub = 3 - (val.includes(".") ? val.length - val.indexOf(".") : 0);

          // in the future, we will support other currencies
          return Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          })
            .format(val)
            .slice(0, sub ? -sub : undefined)
            .replace("$", ""); // strip currency sign because we handle it in prependText
        };
      } else {
        // this is lame, but switching old escape char for new -- remove in future
        mask = Array.isArray(mask) ? mask.map((m) => m.replace(/\\/g, "!")) : mask.replace(/\\/g, "!");
        reversed = Array.isArray(mask) ? any((m) => m.indexOf("9") > -1, mask) : mask.indexOf("9") > -1;
      }
    }
    return {
      mask,
      // @ts-ignore - maska type doesn't accept function but shows in their docs
      preProcess,
      // @ts-ignore - same
      postProcess,
      tokens,
      reversed,
    };
  } else {
    return null;
  }
}

export function applyMask(value: String | Number, mask: string): string {
  let maskOptions: MaskInputOptions;
  if (typeof mask !== "function" && mask.indexOf(MASK_SEPARATOR) > -1) {
    maskOptions = getMaskOptions(mask.split(MASK_SEPARATOR));
  } else {
    maskOptions = getMaskOptions(mask);
  }
  const requestedMask = new Mask(maskOptions);

  // This code mimic's maska library's MaskInput.processInput, otherwise preProcess/postProcess are not called.
  let valueNew = String(value ?? "");
  if (maskOptions.preProcess) {
    valueNew = maskOptions.preProcess(valueNew);
  }
  let masked = requestedMask.masked(valueNew);
  if (maskOptions.postProcess) {
    masked = maskOptions.postProcess(masked);
  }

  return masked;
}

/**
 * FILE UPLOAD SPECIFIC STUFF
 */
const pdfTypes = {
  pdf: "application/pdf",
};

const docFileTypes = {
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // "application/vnd.ms-word.document.macroEnabled.12", // .docm // no macros!
  // "application/vnd.ms-word.template.macroEnabled.12", // .dotm // no macros!
};

const imageTypes = {
  png: "image/png",
  jpg: "image/jpeg",
  // "image/gif",
  // "image/bmp",
  // "image/tiff",
  // "image/webp"
};

const spreadsheetFileTypes = {
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csv: "text/csv",
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.template", // .xltx
  // "application/vnd.ms-excel.sheet.macroEnabled.12", // .xlsm // no macros!
  // "application/vnd.ms-excel.template.macroEnabled.12", // .xltm // no macros!
  // "application/vnd.ms-excel.addin.macroEnabled.12", // .xlam // no macros!
  // "application/vnd.ms-excel.sheet.binary.macroEnabled.12", // .xlsb // no macros!
};

const presentationFileTypes = {
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  // "application/vnd.openxmlformats-officedocument.presentationml.template", // .potx
  // "application/vnd.openxmlformats-officedocument.presentationml.slideshow", // .ppsx
  // "application/vnd.ms-powerpoint.addin.macroEnabled.12", // .ppam // no macros!
  // "application/vnd.ms-powerpoint.presentation.macroEnabled.12", // .pptm // no macros!
  // "application/vnd.ms-powerpoint.template.macroEnabled.12", // .potm // no macros!
  // "application/vnd.ms-powerpoint.slideshow.macroEnabled.12", // .ppsm // no macros!
};

const archivalTypes = {
  zip: "application/zip",
};

export function getOcrTaxAllowedFileTypes(exts?: boolean) {
  return formatFileTypes(
    {
      ...pdfTypes,
      ...imageTypes,
    },
    exts,
  );
}

export function getOcrBankAllowedFileTypes(exts?: boolean) {
  return formatFileTypes(
    {
      ...pdfTypes,
      ...docFileTypes,
      ...imageTypes,
    },
    exts,
  );
}

export function getAllAllowedFileTypes(exts?: boolean) {
  return formatFileTypes(
    {
      ...pdfTypes,
      ...docFileTypes,
      ...imageTypes,
      ...spreadsheetFileTypes,
      ...presentationFileTypes,
      ...archivalTypes,
    },
    exts,
  );
}

export function getImageFileTypes(exts?: boolean) {
  return formatFileTypes(imageTypes, exts);
}

export function getSpreadsheetFileTypes(exts?: boolean) {
  return formatFileTypes(spreadsheetFileTypes, exts);
}

function formatFileTypes(filesTypesMap: Dictionary<string>, exts?: boolean) {
  const fileTypes = exts ? Object.keys(filesTypesMap) : Object.values(filesTypesMap);

  if (!exts) {
    return fileTypes.join(",");
  }

  return fileTypes
    .reduce((acc: string[], ext) => {
      if (fileTypes.includes(`${ext}x`)) {
        return acc;
      }

      if (ext.endsWith("x")) {
        return [...acc, ext.replace(/x$/, "(x)")];
      }

      return [...acc, ext];
    }, [])
    .map((ext) => `.${ext}`)
    .join(", ");
}

export function isUniqueValueQuestion(data: JSONQuestion): boolean {
  return data.uniqueValueAllConnections;
}

export async function isUniqueValue(data: JSONQuestion, value: any): Promise<boolean> {
  logger.trace(() => `isUniqueValue ${data.key} => ${data.uniqueValueAllConnections}`);
  if (isUniqueValueQuestion(data)) {
    try {
      const justKey = last(data.key.split("."));
      const response: AxiosResponse<boolean> = await httpPost<boolean>("/api/entities/unique_in_connections", {
        key: justKey,
        value,
      });
      return response.data;
    } catch (err) {
      handleXhrError(RegisteredErrors.GLOBAL, `Error while determining unique value`, err);
    }
  }
  return true;
}

export function isUniqueTagQuestion(data: JSONQuestion): boolean {
  return any((t) => data.tags?.includes(t), UNIQUE_TAGS);
}

export async function isUniqueTagValue(data: JSONQuestion, value: any): Promise<boolean> {
  logger.trace(() => `isUniqueTagValue ${data.key} => ${data.tags}`, value);
  if (isUniqueTagQuestion(data)) {
    try {
      const justKey = last(data.key.split("."));
      const response: AxiosResponse<boolean> = await httpPost<boolean>("/api/entities/unique_tag", {
        key: justKey,
        value,
        tags: data.tags,
      });
      return response.data;
    } catch (err) {
      handleXhrError(RegisteredErrors.GLOBAL, `Error while determining unique tag value`, err);
    }
  }
  return true;
}
