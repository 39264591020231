import isEqual from "lodash/isEqual";
import moment from "moment-timezone";
import {
  addIndex,
  any,
  Dictionary,
  equals,
  filter,
  flatten,
  head,
  is,
  isEmpty,
  isNil,
  keys,
  map,
  reject,
  values,
  zipObj,
} from "ramda";
import {allLanguagesUnanswered, getFreeTextAnswerWithFallback} from "../answers/values";
import type {LocaleObject} from "../api/answers";
import {ListConfig} from "../api/lists";
import type {VisibleStages} from "../connections";
import type {ConnectionRole} from "../enums";
import {GroupSubType, GroupType, Internal, QuestionType, Stage} from "../enums";
import {KitType, SearchColumnType} from "../enums/element";
import {SourceOfTruth} from "../enums/question";
import type {Expression} from "../rules/expression";

export type JSONPrimitive = string | number | boolean | null | undefined | Date;
export type JSONValue = JSONPrimitive | JSONObject | JSONArray | LocaleObject;

export interface JSONObject {
  [k: string]: JSONValue;
}

export interface JSONArray extends Array<JSONValue> {}

export interface ComponentOptions extends JSONObject {
  serverUrlExtraParams: string; // For file / image component (e.g., &connectionId=12345)
}

export interface KeyedStrings {
  [key: string]: string;
}

export interface JSONQuestion extends JSONObject {
  _id: string;
  key: string;
  questionId?: string;
  owner?: string;
  children?: JSONQuestion[];
  parent?: JSONQuestion;
  type: QuestionType | GroupType;
  subType?: GroupSubType;
  tags?: string[];
  messageCount?: {count: number; unreadCount: number};
  approvalCount?: {count: number; incompleteCount?: number; mostRecentIsApproved?: boolean};
  noteCount?: {count: number};
  reminderCount?: {count: number; overdueCount: number};
  taskCount?: {count: number};
  changedSinceLastReviewCount?: {count: number};
  required: boolean;
  visible?: boolean;
  quickHideVisible?: boolean;
  filterVisible?: boolean;
  secured?: boolean;
  recentlyChanged?: boolean;
  instanceRecentlyDeleted?: boolean;
  instance?: string;
  internalUse?: boolean;
  default?: string | JSONObject;
  externalValidation?: boolean;
  requiredAtConnectionStage?: Stage;
  optionsAnswerKey?: string;
  columns?: JSONObject[];
  label?: string;
  labelMarkdown?: string;
  helpText?: string;
  helpTextMarkdown?: string;
  proceedLabel?: string;
  proceedUrl?: string;
  visibilityRule?: Expression;
  visibleStages?: VisibleStages;
  requiredLanguages?: string[];
  requiredSubparts?: string[];
  // valuesKey?: string;
  list?: ListConfig;
  options?: any;
  hideFromAPI?: boolean;
  forceArrayInAPI?: boolean;
  multiple?: boolean;
  includeAsChildInResponder?: boolean;
  includeInContactCenter?: boolean;
  includeInDocumentCenter?: boolean;
  componentOptions?: ComponentOptions;
  possibleScores?: number[];
  optionsFilter?: Expression;
  actions?: JSONObject[];
  leftSelectTitle?: string;
  rightSelectTitle?: string;
  unansweredRequiredCount?: number;
  unansweredRequiredInternalUseCount?: number;
  highRiskCount?: number;
  unknownRiskCount?: number;
  requiredForSave?: boolean;
  imageShape?: string;
  groupValues?: string;
  groupLabel?: string;
  config?: JSONObject;
  sequence?: number;
  owningRole?: string;
  other?: JSONObject; // doesn't like SelectOptions
  hide?: boolean;
  disregardedEntityBadge?: boolean;
  valuesParentAnswerKey?: string;
  sourceOfTruth?: SourceOfTruth;
  disallowQuestionAdminChanges?: boolean;
  applySegmentationToOptions?: boolean;
  entityTableSearchScope?: JSONObject; // doesn't like EntityTableSearchScope
  sortColumns?: any[];
  allowForSinglePrimary?: boolean;
  allowForMultiplePrimary?: boolean;
  profileRole?: string;
  includeContactTitle?: boolean;
  includeContactPhone?: boolean;
  includeContactFax?: boolean;
  includeContactCountry?: boolean;
  groupTemplateAnswerKey?: string;
  uniqueValueAllConnections?: boolean;
  validationExpression?: Expression;
  overrideReadOnly?: boolean;
  entitySearchFilter?: Expression;
  disallowAdd?: boolean;
  disallowAddWhen?: Expression;
  disallowDelete?: boolean;
  disallowDeleteWhen?: Expression;
  isBitSightTier?: boolean;
  associatedKitTypes?: KitType[];
  alwaysOn?: boolean;
  redirectAnswer?: Expression;
  actorRole?: ConnectionRole;
  roleTableRole?: string;
  myConnectionKey?: boolean;
  runGiact?: boolean;
}

export function toJSON(o: object | undefined | null): string {
  return JSON.stringify(o, null, 2);
}

export class ProfileEvent {
  constructor(
    private readonly elapsed: number,
    private readonly totalElapsed: number,
    private readonly event: string,
  ) {}

  public toString() {
    return (
      `${moment.duration(this.elapsed).asSeconds().toFixed(3)} ` +
      `[${moment.duration(this.totalElapsed).asSeconds().toFixed(3)}] ` +
      `- ${this.event}`
    );
  }
}

export class ProfileTimer {
  private readonly startTime: number;
  private lastTime: number;

  constructor() {
    this.startTime = this.lastTime = new Date().valueOf();
  }

  public ping(event: string): ProfileEvent {
    const now = new Date().valueOf();
    const elapsed = now - this.lastTime;
    const totalElapsed = now - this.startTime;
    this.lastTime = now;
    return new ProfileEvent(elapsed, totalElapsed, event);
  }
}

export function isEmptyOrUndefined(value) {
  return isEmpty(value) || value === undefined || value === null;
}

function isIterable(input) {
  if (input === null || input === undefined) {
    return false;
  }

  return typeof input[Symbol.iterator] === "function";
}

export function isUnanswered(answer, requiredLanguages?: string[], requiredSubparts?: string[]) {
  if (
    isEmptyOrUndefined(answer) ||
    (!is(Date, answer) &&
      !requiredLanguages &&
      !requiredSubparts &&
      typeof answer === "object" &&
      !any((k) => k !== "metaData" && !isEmptyOrUndefined(answer[k]), Object.keys(answer)))
  ) {
    return true;
  }
  if (isIterable(requiredLanguages)) {
    try {
      for (const language of requiredLanguages || []) {
        if (!getFreeTextAnswerWithFallback(answer, language)) {
          return true;
        }
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
    }
  }

  for (const requiredSubpart of requiredSubparts || []) {
    if (!Array.isArray(answer)) {
      answer = [answer];
    }
    for (const answerPart of answer) {
      if (
        isEmptyOrUndefined(answerPart?.[requiredSubpart]) &&
        isEmptyOrUndefined(answerPart?.[Internal.HYDRATED_ANSWER_KEY]?.[requiredSubpart])
      ) {
        return true;
      }
    }
  }

  return allLanguagesUnanswered(answer);
}

/**
 * Used to handle more advanced types like address as well as the typical question types
 * @param answer
 * @param type
 * @param requiredLanguages
 * @param requiredSubparts
 */
export function advancedIsUnanswered(answer, type: string, requiredLanguages?: string[], requiredSubparts?: string[]) {
  const isObject = typeof answer === "object";
  if (isObject && type === QuestionType.ADDRESS) {
    return !answer?.complete;
  } else if (isObject && type === QuestionType.ROUTING) {
    return !answer?.valid;
  } else {
    return isUnanswered(answer, requiredLanguages, requiredSubparts);
  }
}

const indexedFilter: <T>(t1: (item: T, idx: number) => boolean, t2: T[]) => T[] = addIndex(filter);
export async function asyncFilter<T>(predicate: (item: T) => Promise<boolean>, items: T[]): Promise<T[]> {
  const include = await Promise.all(map(predicate, items));
  return indexedFilter((_, idx) => include[idx], items);
}

export async function asyncChain<T, U>(f: (item: T) => Promise<U[]>, items: T[]): Promise<U[]> {
  return flatten<U>(await Promise.all(map(f, items)));
}

export function sleep(milliseconds) {
  return new Promise<void>((resolve) => setTimeout(resolve, milliseconds));
}

export const SECONDS_PER_DAY = 24 * 60 * 60;
export const MILLISECONDS_PER_DAY = SECONDS_PER_DAY * 1000;
export const MILLISECONDS_PER_HOUR = 60 * 60 * 1000;
export const MILLISECONDS_PER_MINUTE = 60000;

export const isInconsistent = <T, S>(f: (T) => S, a: T[]) => {
  if (a.length > 0) {
    const h = f(head(a));
    return any((s) => !equals(f(s), h), a.slice(1));
  } else {
    return false;
  }
};

export function bust(arg: string | (() => Error)): never {
  if (typeof arg === "string") {
    throw new Error(arg);
  } else {
    throw arg();
  }
}

/* profile for pg-iso profiling */
export interface ProfilingFacade {
  ping(event: string | (() => string)): void;
}

let profiler: ProfilingFacade | null = null;
export function registerProfiler(facade: ProfilingFacade) {
  profiler = facade;
}

export class Profiler {
  public static ping(event: string | (() => string)): void {
    if (profiler) {
      profiler.ping(event);
    }
  }
}

export function coerceToArray<T>(v: T | T[] | null | undefined): T[] {
  if (v === undefined || v === null) {
    return [];
  }
  if (Array.isArray(v)) {
    return v;
  }
  return [v];
}

export function coerceFromArray<T>(v: T | T[]): T {
  if (Array.isArray(v)) {
    return v[0];
  } else {
    return v;
  }
}

export function setTarget(keyPath: string[], value: any, target: {}) {
  if (keyPath.length === 1) {
    target[keyPath[0]] = value;
  } else {
    if (!target[keyPath[0]]) {
      target[keyPath[0]] = {};
    }
    setTarget(keyPath.slice(1), value, target[keyPath[0]]);
  }
}

export function compact<T>(v: Array<T | null | undefined>): T[] {
  return reject(isNil)(v);
}

export function makeKey(v: any[]): string {
  return compact(v).join("-");
}

export function mongoSafeKey(s: string): string {
  return s.replace(/\./g, "_");
}

export function makeReversibleMongoSafeKey(key: string): string {
  return key.replace(/~/g, "~t").replace(/\./g, "~p").replace(/^\$/g, "~d");
}

export function reverseReversibleMongoSafeKey(escapedKey: string): string {
  return escapedKey.replace(/^~d/g, "$").replace(/~p/g, ".").replace(/~t/g, "~");
}

export function validEmail(email: string): boolean {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function extractEmailDomain(email: string) {
  return email.split("@")[1];
}

export function isEscaped(value: string): boolean {
  let count = 0;
  while (count < value.length && value.charAt(value.length - 1 - count) === "\\") {
    count++;
  }
  return count % 2 === 1;
}

export function escapedSplit(delimiter: string, value: string): string[] {
  const split = value.split(delimiter);
  if (split.length === 0) {
    return [];
  }
  const result: string[] = [split[0]];
  for (let idx = 1; idx < split.length; idx++) {
    const prevResult = result[result.length - 1];
    if (isEscaped(prevResult)) {
      result[result.length - 1] = prevResult.substr(0, prevResult.length - 1) + delimiter + split[idx];
    } else {
      result.push(split[idx]);
    }
  }
  return result;
}

export function unescape(value: string): string {
  return value.replace(/\\\\/g, "\\");
}

export const promiseProps: <T>(
  obj: Dictionary<T | Promise<T>> | Promise<Dictionary<T | Promise<T>>>,
) => Promise<Dictionary<T>> = async <T>(obj) => {
  const obj1 = await obj;
  return Promise.all<T>(values(obj1)).then((v) => zipObj(keys(obj1) as string[], v));
};

export function jsonClone(v: any): any {
  return JSON.parse(JSON.stringify(v));
}

export function randomHex(halfLength = 16): string {
  // the `* 2` if from a legacy holdover from `randomBytes`
  return [...Array(halfLength * 2)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
}

export async function asyncDepthFirstSearch<T>(
  predicate: (t: T) => boolean,
  children: (t: T) => Promise<T[]>,
  t: T,
): Promise<T | undefined> {
  if (predicate(t)) {
    return t;
  }
  for (const child of await children(t)) {
    const r = await asyncDepthFirstSearch(predicate, children, child);
    if (r) {
      return r;
    }
  }
  return undefined;
}

export function ensureArray<T>(arg: T | T[]): T[] {
  return Array.isArray(arg) ? arg : [arg];
}

export interface WaitForSelectorOptions {
  selector: string;
  timeout: number;
  target: Element;
  throwOnError?: boolean;
}

export enum WaitForSelectorFailReason {
  Timeout = "Timeout",
  ElementNotFound = "ElementNotFound",
}

export async function waitForSelector({
  selector,
  timeout,
  target,
  throwOnError = false,
}: WaitForSelectorOptions): Promise<WaitForSelectorFailReason | undefined> {
  if (!target) {
    if (throwOnError) {
      throw new Error(`[${WaitForSelectorFailReason.ElementNotFound}] Failed to find target element.`);
    }

    return WaitForSelectorFailReason.ElementNotFound;
  }

  return new Promise<WaitForSelectorFailReason | undefined>((resolvePromise, rejectPromise) => {
    // sometimes it's already on the page, and there may not be any more mutations, so we need a manual check
    if (!!target.querySelector(selector)) {
      resolvePromise(undefined);
      return;
    }

    // create an observer instance
    const observer = new MutationObserver(() => {
      const foundMatch = !!target.querySelector(selector);

      if (foundMatch) {
        clearTimeout(timeoutId);
        observer.disconnect();
        resolvePromise(undefined);
      }
    });

    observer.observe(target, {childList: true, subtree: true});

    const timeoutId = setTimeout(() => {
      observer.disconnect();

      if (throwOnError) {
        rejectPromise(
          new Error(
            `[${WaitForSelectorFailReason.Timeout}] Timed out waiting for '${selector}' to appear after ${timeout}ms.`,
          ),
        );
      } else {
        resolvePromise(WaitForSelectorFailReason.Timeout);
      }
    }, timeout);
  });
}

export interface StickyScrollOptions {
  elem: string | Element;
  scrollContainer?: Element;
  offset?: number;
  duration?: number;
}

type StopObservingCallback = () => void;

export function stickyScroll({
  elem: elemInput,
  scrollContainer = document.documentElement,
  offset = 0,
  duration = 3000,
}: StickyScrollOptions): StopObservingCallback {
  const elem: Element | null = typeof elemInput === "string" ? scrollContainer.querySelector(elemInput) : elemInput;

  if (!elem) {
    throw new Error(`Could not find scrollContainer.querySelector(${elemInput})`);
  }

  const scrollContainerRect = scrollContainer.getBoundingClientRect();
  const elemRect = elem.getBoundingClientRect();

  const observer = new MutationObserver(() => {
    if (elemRect.top !== Math.round(-offset)) {
      scrollContainer.scrollTo(0, -scrollContainerRect.top + elemRect.top + offset);
    }
  });

  observer.observe(scrollContainer, {childList: true, subtree: true});

  let wasMouseWheel = false;

  const timeout = setTimeout(() => stopObserving(), duration);

  window.addEventListener("mousewheel", triggeredByMouseWheel);
  window.addEventListener("scroll", stopObserving);

  return () => stopObserving();

  function stopObserving(event?: Event) {
    if (event && !wasMouseWheel) {
      wasMouseWheel = false;
      return;
    }

    clearTimeout(timeout);

    window.removeEventListener("mousewheel", triggeredByMouseWheel);
    window.removeEventListener("scroll", stopObserving);

    observer.disconnect();
  }

  function triggeredByMouseWheel() {
    wasMouseWheel = true;
  }
}

// type SomeType = { [key in SomeOtherObj]: any } is an error,
// but if we promise it's a string (via the Extract), they will allow it
export type KeysOf<T> = Extract<keyof T, string>;

// modifies arr - finds `search` and replaced with `replaceWith`, if not found appends `replaceWith`
export function replaceOrAppend<T>(arr: T[], search: T, replaceWith: T) {
  const match = arr.find((v) => isEqual(v, search));
  if (match) {
    const index = arr.indexOf(match);
    arr.splice(index, 1, replaceWith);
  } else {
    arr.push(replaceWith);
  }
}

const isoDateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9-Z:]*)?$/;
export function isISODate(s: string): boolean {
  return isoDateRegex.test(s);
}

// on the backend, may be a ref
export type StringOrRef = string;
export type IdLike = StringOrRef | {_id: StringOrRef} | {id: StringOrRef};

/**
 * Similar to `asIdString`, but so it can be used on the front and back end.
 * PREFER `asIdString` outside of `pg-isomorphic` and `web-app`.
 */
export function extractId(input: undefined): undefined;
export function extractId<T extends IdLike>(input: T): string;
export function extractId<T extends IdLike>(input?: T): string | undefined;
export function extractId<T extends IdLike>(input?: T): string | undefined {
  if (typeof input === "string") {
    return input;
  }

  const id = (input as any)?._id || (input as any)?.id;

  return id && `${id}`;
}

export function getFirstLastName(userProfile: {
  firstName?: string;
  lastName?: string;
  displayName?: string;
}): [string, string] {
  if (userProfile.firstName && userProfile.lastName) {
    return [userProfile.firstName, userProfile.lastName];
  } else {
    const names = userProfile.displayName!.split(/\s+/);
    if (names.length >= 2) {
      return [names[0], names[1]];
    } else if (names.length === 1 && names[0]) {
      return [names[0], names[0]];
    } else {
      throw new Error("Missing first and last name");
    }
  }
}

// Return nearest midnight of given date in UTC.
export function parseDate(dbDate: any): moment.Moment {
  const m = moment.utc(dbDate);
  if (m.hours() >= 12) {
    // Must be next day.
    m.add(1, "day");
  }
  m.startOf("day");
  return m;
}

// Return midnight for today in UTC.
export function todayMoment(timezone?: string): moment.Moment {
  const now = moment();
  if (timezone) {
    now.tz(timezone);
  }
  const m = moment.utc([now.year(), now.month(), now.date()]);
  return m;
}

// Format given date as a plain date string, rounding day if needed.
export function formatDate(dbDate: string | Date | moment.Moment, format = "YYYY-MM-DD"): string {
  if (!dbDate) {
    return "";
  }

  // Parse date as UTC, to avoid adjusting bare dates which moment() does.
  const m = moment.utc(dbDate);
  if (m.hours() >= 12) {
    // Must be next day.
    // For instance, Sydney Australia, UTC+10, midnight on Apr 13 is 2024-04-12T14:00:00.000Z in UTC.
    // Notice that is 14 hours but the day BEFORE.
    m.add(1, "day");
  }
  return m.format(format);
}

export function mapQuestionTypeToSearchColumnType(type: QuestionType): SearchColumnType {
  switch (type) {
    case QuestionType.URL:
      return SearchColumnType.URL;
    case QuestionType.DATE:
      return SearchColumnType.DATE;
    case QuestionType.DATE_TIME:
      return SearchColumnType.DATE_TIME;
    case QuestionType.ADDRESS:
      return SearchColumnType.ADDRESS;
    case QuestionType.USER_SELECT:
      return SearchColumnType.OWNER;
    case QuestionType.CONTACT:
      return SearchColumnType.OWNER;
    default:
      return SearchColumnType.ANSWER;
  }
}
