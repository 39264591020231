import type {Dictionary} from "lodash";
import {
  Connection,
  ConnectionRole,
  ConnectionSearchStatus,
  ElementType,
  Entity as Ent,
  Internal,
  QuestionType,
  SearchScope,
  Stage,
  Subsidiaries,
} from "../enums";
import {MagicAnswerKeys} from "../enums/answers";
import {DayType} from "../enums/calendar";
import {KitType, SearchColumnType} from "../enums/element";
import {KitSearchScope, SearchChartType, SpecialSearchFields} from "../enums/search";
import {Waiting} from "../enums/waiting";
import {Expression} from "../rules/expression";
import type {AnswerSearchResults, Filters} from "./answers";
import type {Badge} from "./badge";
import type {APIConnection} from "./connection";
import type {BasicEntityInfo} from "./entity";
import type {Card} from "./guidedbuying";
import type {Localizable} from "./index";
import {ListConfig} from "./lists";
import type {SearchCondition, SearchConditionRoot} from "./searchcondition";
import type {GetTaskParameters} from "./tasks";

export interface SelectOptions {
  label: string;
  value: string;
  color?: string;
}

export interface LocalizableSelectOptions {
  label: Localizable<string>;
  value: string;
  color?: string;
}

export interface APISearchEntityParams {
  query: string;
  filters?: Filters;
  limit?: number;
  page?: number;
  connectionRole?: string;
  sort?: Array<{[field: string]: string}>;
  entityIds?: string[];
  includeActiveEntity?: boolean;
  scope?: SearchScope;
  noSourceFields?: boolean;
  allSourceFields?: boolean;
  extraSourceFields?: string[];
  includeConnectionRoles?: string[]; // used by the export to include selected connection roles in the results
  conditions?: SearchCondition;
  connectionNetworkStatus?: ConnectionSearchStatus;
  taskCount?: boolean;
  download?: boolean;
  onLastClosedReview?: boolean;
  familyTreeConnections?: boolean;
  elementId?: string;
  // only used on client?
  conflict?: boolean;
  myConnections?: boolean;
  homeConnections?: boolean;
  adminSeeAllConnections?: boolean;
  searchAllWithPriority?: boolean;
  casualSearch?: boolean;
  entityIdsToExclude?: string[];
  includeConnectionNetworkStatuses?: ConnectionSearchStatus[]; // for guided buying, search for multiple statuses
  fromEntityId?: string; // for graphite admins, so they can override the from entity
  connectionId?: string;
  includePublic?: boolean;
  kitType?: KitType; // for searchColumns
  doNotCollapse?: boolean;
  searchFilter?: Expression;
  logQuery?: boolean;
  isConnectionsSearch?: boolean;
  advancedSearchQuestions?: AdvancedSearchFieldGroup[];
  kitAssociatedEntityIds?: string[];
  sharingMasterDataType?: string;
  sharingMasterDataInstanceID?: string;
  includeUsingFlag?: boolean;
}

export interface APISearchKitParams {
  kitType: KitType;
  groupBy: string[];
  currentGroupByTabs?: string[];
  query?: string;
  scope?: KitSearchScope;
  filters?: {[answerKey: string]: string | string[]};
  limit?: number;
  page?: number;
  sort?: Array<{[field: string]: string}>;
  maxGroupSize?: number; // default bucket size to 20
  entityIds?: string[];
  conditions?: SearchCondition;
  rowFilterConditions?: SearchCondition;
  myKits?: boolean;
  specificKitIds?: string[];
  skipRowFilters?: boolean;

  // For counterparty kit searches. Must include both. (but can provide only connection ID for connection search against active entity)
  owningEntityId?: string;
  connectionId?: string;
}

export interface APISearchTaskParams extends GetTaskParameters {
  groupBys: string[];
  currentGroupByTabs?: string[];
  query?: string;
  filters?: Filters;
  conditions?: SearchCondition;
  onlyNotViewedUpdates?: boolean;
  onlySingleOwner?: boolean;
  onlyMultipleOwners?: boolean;
  page?: number;
  limit?: number;
}

export interface AdvancedSearchFieldGroup {
  label: string; // this is the topic
  items: AdvancedSearchField[];
  sequence: number;
}

// If the field is in a multiple, it includes the group key (i.e Security_Review.Risk_Level)
export interface AdvancedSearchField {
  questionId: string; // unique question ID from the hammer
  key: string;
  label: string; // this comes from searchName
  type: ElementType | "none";
  options?: SelectOptions[];
  subOptions?: {[key: string]: SelectOptions[]};
  list?: ListConfig;
  isQuickFilter?: boolean;
  quickFilterMultiple?: boolean;
  useOptionValue?: boolean;
  sequence?: number;
  includeSubOptions?: boolean;
  subOptionsKeyMap?: {[key: string]: string};

  // these are added in the client
  passThru?: any;
  multiple?: boolean;
  hideLabel?: boolean;
  hidePills?: boolean;
  forSearch?: boolean;
}

export interface APISearchCardParams {
  query?: string;
  filters?: Filters;
  limit?: number;
  page?: number;
  sort?: {[field: string]: number};
}

// Map between
export const magicAnswerKeyMap: Dictionary<string> = {
  [SpecialSearchFields.ConnectionRole]: "connectionRole",
  [SpecialSearchFields.ConnectionNetworkStatus]: "connectionNetworkStatus",
};

export interface MagicConditions {
  connectionNetworkStatus?: ConnectionSearchStatus;
  connectionRole?: ConnectionRole;
}

const textTypes = new Set<ElementType>([
  QuestionType.TEXT,
  QuestionType.MULTI_TEXT,
  QuestionType.SELECT,
  QuestionType.RADIO,
  QuestionType.RADIO_OTHER,
  QuestionType.BOOLEAN,
  QuestionType.CHECKBOX,
  QuestionType.CHECKBOX_OTHER,
  QuestionType.CHECKLIST_VIEW,
  QuestionType.COMBO,
  QuestionType.TEXTAREA,
  QuestionType.TWO_LIST_MULTI_SELECT,
  QuestionType.MULTI_LANGUAGE_TEXT,
  QuestionType.EMAIL,
]);

export function isTextQuestionType(t?: QuestionType): boolean {
  if (!t) {
    return false;
  }
  return textTypes.has(t);
}

export interface ColumnERPConfig {
  blockedFlag?: string[];
  addressHeaderAnswerKey?: string;
}

// Represents interface to display a column in a table. Stored in various tables such as SearchColumn and CampaignCompanyColumn.
export interface ColumnDefinition {
  /* extends BvTableField { */ owner: string;
  connectionRole?: string | null;
  sequence: number;
  type: SearchColumnType;
  label: string;
  labelMarkdown?: string;
  key: string;
  groupReference?: string;
  additionalKeys?: string[];
  sortable?: boolean;
  config?: ColumnERPConfig;
  skipLocalization?: boolean;
  options?: SelectOptions[] | LocalizableSelectOptions[]; // Only custom questions have these defined.
  groupable?: boolean;
  filterableSequence?: number;
  defaultGroupBy?: boolean;
  multiple?: boolean;
  list?: ListConfig;
  configurability?: string;
}

export interface CTColumnDefinition {
  type: SearchColumnType;
  key: string;
  label: string;
  sortable?: boolean;
  thClass?: string;
  options?: SelectOptions[] | LocalizableSelectOptions[]; // Only custom questions have these defined.
}

export interface CTLoadDataRequest {
  sortBy?: string;
  sortDesc?: boolean;
  page: number;
}

// These are the answers needed to display a row in a CustomTable.
export interface CustomTableRow {
  displayAnswers?: {[answerKey: string]: any};
}

// CustomTable's version of Primevue's MenuItem.
export interface CTMenuItem {
  label: string;
  command: (row: CustomTableRow) => void;
}

export interface SubsidiaryListRow {
  _id: string;
  Subsidiary_Name: string;
  Subsidiary_Country: string;
}

export interface EntityDocument extends CustomTableRow {
  entityId: string;
  publicId?: string;
  privateProfile: boolean;
  displayName?: string;
  displayBadges?: Badge[];
  isSkeleton?: boolean;
  addedDate?: Date;
  [Ent.Name]?: string;
  [Ent.Logo]?: any[];
  [Subsidiaries.PARENT_NAME]?: string;
  [Subsidiaries.SUBSIDIARY_LIST]?: SubsidiaryListRow[];
}

export interface ConnectionDocument extends EntityDocument {
  owningEntityId: string;
  requestingEntityId?: string;
  connectionId: string;
  // this key correlates translation key, hence the added `invite_no_accept`
  status: Stage | "invite_no_accept";
  owningEntityRole: string;
  pertainingToEntityRole: string;
  isSkeleton: boolean;
  transitionDate?: Date;
  statusNumber: number;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  tasksCompleted?: number;
  tasksTotal?: number;
  hasIncompletedTasks?: boolean;
  waitingOn?: Waiting;
  familyTreeConnections?: FamilyTreeConnection[];
  connection?: APIConnection;
  [Internal.OVERALL_RISK]?: string;
  [Internal.COMPANY_UPDATE_DATE]?: string;
  [Connection.SUPPLIER_DISCONNECT_REASON]?: string;
  inviteSLA?: number;
  inviteSLAMet?: boolean;
  acceptSLA?: number;
  acceptSLAMet?: boolean;
  collaborateSLA?: number;
  collaborateSLAMet?: boolean;
  connectSLA?: number;
  connectSLAMet?: boolean;
  slaDayType?: DayType;
  [Connection.SupplierPrimaryBusinessOwnerName]?: string;
  [Connection.SupplierPrimaryBusinessOwnerEmail]?: string;
  [Connection.SupplierSecondaryBusinessOwnerName]?: string;
  [Connection.SupplierSecondaryBusinessOwnerEmail]?: string;
  [MagicAnswerKeys.ACTIVE_PROFILE]?: string;
  [Internal.PROFILE_UPDATE_DATE]?: string;
  firstTaskReadDate?: string;
  [Internal.UNVERIFIED]?: boolean;
  onHold?: boolean;
  usingMasterData?: boolean;
}

export type ConnectionDocumentLite = Pick<ConnectionDocument, "entityId" | "connectionId" | Ent.Name | "publicId">;

export interface FamilyTreeConnection extends BasicEntityInfo {
  connectionRole: ConnectionRole;
}

export interface SearchEntityResults extends AnswerSearchResults {
  results: ConnectionDocument[];
  searchColumns: ColumnDefinition[];
}

export interface SearchKitResultItem {
  [answerKey: string]: any;
}

export interface SearchKitResults {
  kitType: KitType;
  currentTabs?: string[];
  results: SearchKitResultItem[];
  search?: string;
  total: number;
  searchAfter?: string[];
  page: number;
  limit: number;
  /* example of groupByCounts
  "In Progress"(queston option): {
      "total": 13,
      "Sourcing": {
          "total": 12
      },
      "Intake": {
          "total": 1
      }
  },
  "Review"(question option): {
      "total": 6,
      "Intake": {
          "total": 5
      },
      "Sourcing": {
          "total": 1
      }
  },
  [each groupBy key]: {
    "total": 19,
      "Intake": {
          "total": 6
      },
      "Sourcing": {
          "total": 13
      }
  }
   */
  groupByResult?: any;
  readOnly?: boolean;
  disallowDelete?: boolean;
  disallowDeleteWhen?: Expression;
  connectionId?: string;
}

export interface SearchTaskResults {
  currentTabs?: string[];
  results: any[];
  search?: string;
  total: number;
  page: number;
  limit: number;
  /* example of groupByCounts
  "In Progress"(queston option): {
      "total": 13,
      "Sourcing": {
          "total": 12
      },
      "Intake": {
          "total": 1
      }
  },
  "Review"(question option): {
      "total": 6,
      "Intake": {
          "total": 5
      },
      "Sourcing": {
          "total": 1
      }
  },
  [each groupBy key]: {
    "total": 19,
      "Intake": {
          "total": 6
      },
      "Sourcing": {
          "total": 13
      }
  }
   */
  groupByResult?: any;
}

export interface SearchCardResults {
  results: Card[];
  search?: string;
  total: number;
  searchAfter?: string[];
  page: number;
  limit: number;
}

export interface RunSearchIndexMessage {
  jobId?: string;
  initiator?: string;
  initiatingEntity?: string;
  connectionId?: string;
  entity?: string; // Id or publicId
  all?: boolean;
  dropFirst?: boolean;
  includeEntityConnections?: boolean;
  forIndexVersion?: number;
  includeConnections?: boolean;
  trackProgress?: boolean;
}

export interface EntityTableSearchParams {
  kitType: string;
  includePublic: boolean;
  conditions?: SearchConditionRoot;
}

export interface EntityTableSearchScope {
  includePublic?: boolean;
  allowInvite?: boolean;
  conditions?: SearchConditionRoot;
  multiple?: boolean;
  searchParameter?: Dictionary<Expression>;
  allowContinue?: boolean;
  continueText?: string;
  continueButton?: string;
  noResultsText?: string;
}
export interface ChartItem {
  value: number;
  key: string;
  label?: string;
}

export interface ChartDataResponse {
  key: SearchChartType | string; // chart/data type (e.g. 'risk', 'high_risk', 'task_ownership', 'stage')
  values: ChartItem[];
  title?: string; // chart title (maybe we build this from the key?)
}
