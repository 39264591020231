export enum SearchScope {
  ALL = "all",
  NAME = "name",
  COUNTRY = "country",
  PRODUCTS = "products",
  TASK = "task",
}

export enum CalculatedFields {
  ConnectionTransitionDate = "transitionDate",
  RiskScore = "__RiskScore",
  ConnectionStatusNumber = "statusNumber",
}

export enum SearchScopeCategory {
  ENTITY = "entity",
  TASK = "task",
}

export enum SavedSearchScope {
  CONNECTIONS = "connections",
  TASKS = "tasks",
  SEARCH = "search",
  ACTION_PLANS = "action_plans",
  KIT = "kit",
  GUIDED_BUYING_ENTITY_SEARCH = "buying",
  GUIDED_BUYING_MY_ITEMS = "my_items",
  CONTACTS = "contacts",
}

export enum SpecialSearchFields {
  ConnectionRole = "__connectionRole",
  ConnectionRolePlural = "__connectionRolePlural",
  ConnectionNetworkStatus = "__connectionNetworkStatus",
}

export enum KitSearchScope {
  ALL = "all",
}

export enum TaskSearchFields {
  OWNER_ARRAY_FIELD = "effectiveAssignedUsers",
  OWNER_ID = "effectiveAssignedUsers._id",
  MY_COMPANY = "My Company",
  MY_CONNECTION = "My Connection",
  PRIORITY = "priority.label.en-US",
  MY_TEAM = "my_team",
}

export enum GroupBy {
  GROUP_BY_EMPTY = "group_by_empty",
}

export enum SearchLayoutType {
  GRID = "grid",
  LIST = "list",
}

export enum SortDirection {
  ASCEND = "asc",
  DESCEND = "desc",
}

export enum SearchChartType {
  STAGE = "status",
  RISK = "risk",
  HIGH_RISK = "high_risk",
  RTT = "ADBE_Overview_RTT",
  TASK_OWNERSHIP = "task_ownership",
}

export enum SearchChartAnswerKey {
  RISK = "__Overall_Risk",
  HIGH_RISK = "high_risk",
  TASK_OWNERSHIP = "task_ownership",
  STAGE = "status",
  RTT = "ADBE_Overview_RTT",
  CLASSIFICATION = "ADBE_Supplier_Risk_Data_Classification_Final",
}
