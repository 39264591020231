import {RegisteredErrors} from "@/composables/errorHandling/registeredErrors";
import type {ComponentId, ErrorDetails, ErrorHandler, ErrorHandlerEntry} from "@/composables/errorHandling/types";
import {getNextPgId} from "@/composables/utils";
import globalLogger from "@/logging";
import {pathOr} from "ramda";
import {onUnmounted} from "vue";

const logger = globalLogger.getLogger("ErrorHandlerRouter");

/**
 * A simple class to register error handles. If an error happens that does not have a registered handler it will default to the
 * global handler.
 */
class ErrorHandlerRouter {
  /**
   * Tracks error handling registration
   *
   * CONSIDER: we might want to allow more than one registration for an error (switch to array)
   */
  static registeredHandlers = {} as {[handlerKey in RegisteredErrors]: ErrorHandlerEntry[]};

  /**
   * Registers an error handler for an error type (error type must be defined in enum)
   * @param handlerKey
   * @param handler
   */
  static registerHandler(handlerKey: RegisteredErrors, handler: ErrorHandler, componentId?: ComponentId) {
    let handlers = ErrorHandlerRouter.registeredHandlers[handlerKey];
    if (!handlers) {
      handlers = [];
    } else {
      const existingHandler = handlers.find((h) => h.handler === handler);
      if (existingHandler) {
        logger.error(`registerHandler: Duplicate handler registration for ${handlerKey} and ${handler}`);
      }
    }
    handlers.push({handler, componentId});
    ErrorHandlerRouter.registeredHandlers[handlerKey] = handlers;
  }

  /**
   * Unregisters an error handler
   * @param handlerKey
   * @param handler
   */
  static unregisterHandler(handlerKey: RegisteredErrors, handler: ErrorHandler) {
    const handlers = ErrorHandlerRouter.registeredHandlers[handlerKey];
    const updatedHandlers = handlers.filter((h) => h.handler !== handler);
    if (updatedHandlers.length === handlers.length) {
      logger.error(`unregisterHandler: Handler ${handler} not found for ${handlerKey}`);
    }
    ErrorHandlerRouter.registeredHandlers[handlerKey] = updatedHandlers;
  }

  /**
   * Use this method to pass in error. For example in a catch statement.
   * @param handlerKey
   * @param errorDescription
   * @param errorDetails
   * @param componentId
   */
  static routerError(
    handlerKey: RegisteredErrors,
    errorDescription: string,
    errorDetails: ErrorDetails,
    componentId?: ComponentId,
  ) {
    const userHandlerKey =
      ErrorHandlerRouter.registeredHandlers[handlerKey]?.length > 0 ? handlerKey : RegisteredErrors.GLOBAL;
    let handlers = ErrorHandlerRouter.registeredHandlers[userHandlerKey] || [];
    if (componentId) {
      handlers = handlers.filter((h) => h.componentId === componentId);
      if (handlers.length === 0) {
        logger.error(`routerError: No handler found for ${handlerKey} and componentId ${componentId}`);
      }
    }
    if (handlers.length > 1) {
      logger.error(`routerError: Multiple handlers found for ${handlerKey}, this is probably a mistake`);
    }
    for (const handler of handlers) {
      logger.trace(`Routing error to handler ${handlerKey}${componentId ? " for component " : ""}${componentId}`);
      handler.handler(errorDescription, errorDetails);
    }
  }
}

/**
 * A shortcut method to use the ErrorHandlerRouter
 *
 * @param handlerKey
 * @param errorDescription
 * @param errorDetails
 */
export function handleError(handlerKey: RegisteredErrors, errorDescription: string, errorDetails: ErrorDetails) {
  ErrorHandlerRouter.routerError(handlerKey, errorDescription, errorDetails);
}

/**
 * Shortcut method to use the ErrorHandlerRouter, but specifically for XHR request catches
 *
 * @param handlerKey
 * @param errorDescription
 * @param httpError
 * @param componentId
 */
export function handleXhrError(
  handlerKey: RegisteredErrors,
  errorDescription: string,
  httpError: any,
  componentId?: ComponentId,
) {
  ErrorHandlerRouter.routerError(
    handlerKey,
    errorDescription,
    {
      httpStatus: httpError.response?.status,
      error: pathOr("", ["response", "data", "code"], httpError),
      fullRequest: httpError,
    },
    componentId,
  );
}

/**
 * This contains all the code to setup a handler for an error within a composable component. This will setup the handler and unset
 * it when the component is unmounted.
 *
 * While I usually don't like lifecycle stuff hidden in methods, in this case I think it's nice doing everything in a single
 * method.
 *
 * @param handlerKey
 * @param handler
 */
export function setupComponentErrorHandler(
  handlerKey: RegisteredErrors | RegisteredErrors[],
  handler: ErrorHandler,
  componentId?: ComponentId,
) {
  const keys: RegisteredErrors[] = !Array.isArray(handlerKey) ? [handlerKey] : handlerKey;

  for (const key in keys) {
    ErrorHandlerRouter.registerHandler(keys[key], handler, componentId);

    onUnmounted(() => {
      ErrorHandlerRouter.unregisterHandler(keys[key], handler);
    });
  }
}

// If you have multiple instances of the same component, then you should call this and pass it into
// the above functions -- setupComponentErrorHandler and handleXhrError.
export function useComponentId(): string {
  return getNextPgId();
}

(window as any)._ErrorHandlerRouter = ErrorHandlerRouter;
