import {Severity} from "../enums/loader";
import {Permission} from "../permissions";

export interface LoaderError {
  severity: Severity;
  message: string;
  messageMarkdown?: string;
  questionId?: string;
}

export const LOADER_DELETED = "__loader_deleted";

export class LoaderResults {
  public entities: string[] = [];
  public entitiesCreated: number = 0;
  public objectTypesLoaded: LoaderObjectType[] = [];

  public rolesCreated: number = 0;
  public rolesRenamed: number = 0;
  public rolesDeleted: number = 0;

  public groupsCreated: number = 0;
  public groupsUpdated: number = 0;
  public groupsRenamed: number = 0;
  public groupsDeleted: number = 0;

  public questionsCreated: number = 0;
  public questionsUpdated: number = 0;
  public questionsDeleted: number = 0;
  public questionIdsDeleted: string[] = [];
  public groupKeysDeleted: string[] = [];

  public kitQuestionsCreated: number = 0;
  public kitQuestionsUpdated: number = 0;
  public kitQuestionsDeleted: number = 0;
  public kitQuestionIdsDeleted: string[] = [];

  public overridesCreated: number = 0;
  public overridesUpdated: number = 0;
  public overridesDeleted: number = 0;

  public searchColumnsCreated: number = 0;
  public searchColumnsUpdated: number = 0;
  public searchColumnsDeleted: number = 0;

  public riskColumnsCreated: number = 0;
  public riskColumnsUpdated: number = 0;
  public riskColumnsDeleted: number = 0;

  public campaignCompanyColumnsCreated: number = 0;
  public campaignCompanyColumnsUpdated: number = 0;
  public campaignCompanyColumnsDeleted: number = 0;

  public kitsCreated: number = 0;
  public kitsUpdated: number = 0;
  public kitsDeleted: number = 0;

  public kitColumnsCreated: number = 0;
  public kitColumnsUpdated: number = 0;
  public kitColumnsDeleted: number = 0;

  public tableColumnsCreated: number = 0;
  public tableColumnsUpdated: number = 0;
  public tableColumnsDeleted: number = 0;

  public tasksDeleted: number = 0;
  public taskTopicsUpdated: number = 0;
  public messagesDeleted: number = 0;
  public messageThreadsDeleted: number = 0;
  public remindersDeleted: number = 0;
  public notificationsDeleted: number = 0;
  public calendarItemsDeleted: number = 0;

  public valueSetsCreated: number = 0;
  public valueSetsUpdated: number = 0;
  public valueSetsDeleted: number = 0;

  public triggersCreated: number = 0;
  public triggersUpdated: number = 0;
  public triggersDeleted: number = 0;

  public workflowsCreated: number = 0;
  public workflowsUpdated: number = 0;
  public workflowsDeleted: number = 0;

  public visualWorkflowsCreated: number = 0;
  public visualWorkflowsUpdated: number = 0;
  public visualWorkflowsDeleted: number = 0;

  public clientTriggersCreated: number = 0;
  public clientTriggersUpdated: number = 0;
  public clientTriggersDeleted: number = 0;

  public entityConnectionsAdded: number = 0;
  public entityConnectionsUpdated: number = 0;
  public entityConnectionsDeleted: number = 0;

  public netSuiteLookupsAdded: number = 0;
  public netSuiteLookupsUpdated: number = 0;
  public netSuiteLookupsDeleted: number = 0;

  public netSuiteDataMappingsAdded: number = 0;
  public netSuiteDataMappingsUpdated: number = 0;
  public netSuiteDataMappingsDeleted: number = 0;

  public transformationMappingsAdded: number = 0;
  public transformationMappingsUpdated: number = 0;
  public transformationMappingsDeleted: number = 0;

  public transformationsAdded: number = 0;
  public transformationsUpdated: number = 0;
  public transformationsDeleted: number = 0;

  public translationTablesAdded: number = 0;
  public translationTablesUpdated: number = 0;
  public translationTablesDeleted: number = 0;

  public netSuiteSettingsAdded: number = 0;
  public netSuiteSettingsUpdated: number = 0;
  public netSuiteSettingsDeleted: number = 0;

  public intacctLookupsAdded: number = 0;
  public intacctLookupsUpdated: number = 0;
  public intacctLookupsDeleted: number = 0;

  public intacctDataMappingsAdded: number = 0;
  public intacctDataMappingsUpdated: number = 0;
  public intacctDataMappingsDeleted: number = 0;

  public intacctSettingsAdded: number = 0;
  public intacctSettingsUpdated: number = 0;
  public intacctSettingsDeleted: number = 0;

  public elementActionsCreated: number = 0;
  public elementActionsUpdated: number = 0;
  public elementActionsDeleted: number = 0;

  public quickLinkActionsCreated: number = 0;
  public quickLinkActionsUpdated: number = 0;
  public quickLinkActionsDeleted: number = 0;

  public externalInterfacesAdded: number = 0;
  public externalInterfacesUpdated: number = 0;
  public externalInterfacesDeleted: number = 0;

  public cardsAdded: number = 0;
  public cardsUpdated: number = 0;
  public cardsDeleted: number = 0;

  public batchJobsCreated: number = 0;
  public batchJobsUpdated: number = 0;
  public batchJobsDeleted: number = 0;

  public secretsCreated: number = 0;
  public secretsUpdated: number = 0;
  public secretsDeleted: number = 0;

  public masterDataTypesCreated: number = 0;
  public masterDataTypesUpdated: number = 0;
  public masterDataTypesDeleted: number = 0;

  public errors: LoaderError[] = [];

  public syncSpreadsheetBucket?: string;
  public syncSpreadsheetPath?: string;
  public historyItem?: string;
  public originalFilename?: string;
}

export enum LoaderObjectType {
  QUESTION = "QUESTION",
  KIT_QUESTION = "KIT_QUESTION",
  OVERRIDE = "OVERRIDE",
  RISK_COLUMN = "RISK_COLUMN",
  CAMPAIGN_COMPANY_COLUMN = "CAMPAIGN_COMPANY_COLUMN",
  KIT_COLUMN = "KIT_COLUMN",
  TABLE = "TABLE",
  TRANSLATION_TABLE = "TRANSLATION_TABLE",
  TRANSFORMATION_MAPPING = "TRANSFORMATION_MAPPING",
  TRANSFORMATION = "TRANSFORMATION",
  TRIGGER = "TRIGGER",
  ELEMENT_ACTION = "ELEMENT_ACTION",
  QUICK_LINK_ACTION = "QUICK_LINK_ACTION",
  WORKFLOW = "WORKFLOW",
  VISUAL_WORKFLOW = "VISUAL_WORKFLOW",
  ENTITY_SETTINGS = "ENTITY_SETTINGS",
  SEARCH = "SEARCH",
  KIT = "KIT",
  NETSUITE_LOOKUP = "NETSUITE_LOOKUP",
  NETSUITE_MAPPING = "NETSUITE_MAPPING",
  NETSUITE_SETTINGS = "NETSUITE_SETTINGS",
  INTACCT_LOOKUP = "INTACCT_LOOKUP",
  INTACCT_MAPPING = "INTACCT_MAPPING",
  INTACCT_SETTINGS = "INTACCT_SETTINGS",
  EXTERNAL_INTERFACE = "EXTERNAL_INTERFACE",
  VALUE_SET = "VALUE_SET",
  CARD = "CARD",
  CLIENT_TRIGGERS = "CLIENT_TRIGGERS",
  BATCH_JOB = "BATCH_JOB",
  SECRETS = "SECRETS",
  MASTER_DATA_TYPE = "MASTER_DATA_TYPE",
}

export type LoaderObjectTypeSetting = {
  [k in LoaderObjectType]: {permission: Permission; name: string; label: string};
};

export const loaderObjectTypeSettings: LoaderObjectTypeSetting = {
  [LoaderObjectType.QUESTION]: {permission: Permission.LOAD_QUESTIONS, name: "question", label: "Questions"},
  [LoaderObjectType.KIT_QUESTION]: {
    permission: Permission.LOAD_KIT_QUESTIONS,
    name: "kit question",
    label: "Kit questions",
  },
  [LoaderObjectType.OVERRIDE]: {permission: Permission.LOAD_OVERRIDES, name: "override", label: "Overrides"},
  [LoaderObjectType.RISK_COLUMN]: {permission: Permission.LOAD_RISK, name: "risk column", label: "Risk columns"},
  [LoaderObjectType.CAMPAIGN_COMPANY_COLUMN]: {
    permission: Permission.LOAD_CAMPAIGN_COMPANIES,
    name: "campaign company column",
    label: "Campaign company columns",
  },
  [LoaderObjectType.KIT_COLUMN]: {permission: Permission.LOAD_KIT_COLUMNS, name: "kit column", label: "Kit columns"},
  [LoaderObjectType.TABLE]: {permission: Permission.LOAD_TABLES, name: "table column", label: "Table columns"},
  [LoaderObjectType.TRANSLATION_TABLE]: {
    permission: Permission.LOAD_TRANSLATION_TABLES,
    name: "translation table",
    label: "Translation tables",
  },
  [LoaderObjectType.TRANSFORMATION_MAPPING]: {
    permission: Permission.LOAD_TRANSFORMATION_MAPPINGS,
    name: "transformation mapping",
    label: "Transformation mappings",
  },
  [LoaderObjectType.TRANSFORMATION]: {
    permission: Permission.LOAD_TRANSFORMATIONS,
    name: "transformation",
    label: "Transformations",
  },
  [LoaderObjectType.TRIGGER]: {permission: Permission.LOAD_TRIGGERS, name: "trigger", label: "Triggers"},
  [LoaderObjectType.ELEMENT_ACTION]: {
    permission: Permission.LOAD_ELEMENT_ACTIONS,
    name: "element action",
    label: "Element actions",
  },
  [LoaderObjectType.QUICK_LINK_ACTION]: {
    permission: Permission.LOAD_QUICK_LINKS,
    name: "quick link",
    label: "Quick links",
  },
  [LoaderObjectType.WORKFLOW]: {
    permission: Permission.LOAD_WORKFLOWS,
    name: "workflow",
    label: "Workflows",
  },
  [LoaderObjectType.VISUAL_WORKFLOW]: {
    permission: Permission.LOAD_VISUAL_WORKFLOWS,
    name: "visual workflow",
    label: "Visual workflows",
  },
  [LoaderObjectType.ENTITY_SETTINGS]: {
    permission: Permission.LOAD_CONNECTIONS,
    name: "entity setting",
    label: "Entity settings",
  },
  [LoaderObjectType.SEARCH]: {
    permission: Permission.LOAD_SEARCH_COLUMNS,
    name: "search column",
    label: "Search columns",
  },
  [LoaderObjectType.KIT]: {permission: Permission.LOAD_KITS, name: "kit", label: "Kits"},
  [LoaderObjectType.NETSUITE_LOOKUP]: {
    permission: Permission.LOAD_NETSUITE_LOOKUPS,
    name: "NetSuite lookup",
    label: "NetSuite lookups",
  },
  [LoaderObjectType.NETSUITE_MAPPING]: {
    permission: Permission.LOAD_NETSUITE_MAPPINGS,
    name: "NetSuite mapping",
    label: "NetSuite mappings",
  },
  [LoaderObjectType.NETSUITE_SETTINGS]: {
    permission: Permission.LOAD_NETSUITE_SETTINGS,
    name: "NetSuite settings",
    label: "NetSuite settings",
  },
  [LoaderObjectType.INTACCT_LOOKUP]: {
    permission: Permission.LOAD_INTACCT_LOOKUPS,
    name: "Intacct lookup",
    label: "Intacct lookups",
  },
  [LoaderObjectType.INTACCT_MAPPING]: {
    permission: Permission.LOAD_INTACCT_MAPPINGS,
    name: "Intacct mapping",
    label: "Intacct mappings",
  },
  [LoaderObjectType.INTACCT_SETTINGS]: {
    permission: Permission.LOAD_INTACCT_SETTINGS,
    name: "Intacct settings",
    label: "Intacct settings",
  },
  [LoaderObjectType.EXTERNAL_INTERFACE]: {
    permission: Permission.LOAD_EXTERNAL_INTERFACES,
    name: "external interface",
    label: "External interfaces",
  },
  [LoaderObjectType.VALUE_SET]: {permission: Permission.LOAD_VALUE_SETS, name: "value set", label: "Value sets"},
  [LoaderObjectType.CARD]: {permission: Permission.LOAD_CARDS, name: "card", label: "Cards"},
  [LoaderObjectType.CLIENT_TRIGGERS]: {
    permission: Permission.LOAD_CLIENT_TRIGGERS,
    name: "client trigger",
    label: "Client triggers",
  },
  [LoaderObjectType.BATCH_JOB]: {permission: Permission.LOAD_BATCH_JOB, name: "batch job", label: "Batch jobs"},
  [LoaderObjectType.SECRETS]: {permission: Permission.LOAD_SECRETS, name: "secrets", label: "Secrets"},
  [LoaderObjectType.MASTER_DATA_TYPE]: {
    permission: Permission.LOAD_MASTER_DATA_TYPES,
    name: "master data type",
    label: "Master data types",
  },
};

export const LOAD_QUESTIONS_PATH = "loader";

export interface LoadCommand {
  originalFilename?: string;

  // Local file
  xlsxFile?: string;

  // Or GCS file
  gcsBucket?: string;
  gcsSourceFilePath?: string;
  mimetype?: string;

  keepFile?: boolean;
  jobId?: string;

  clear?: boolean;
  spreadsheetSync?: boolean;
  locale?: string;
  dryRun?: boolean;
  userId: string;
  entityId: string;
  suppressEvents?: boolean;
  enableLocalizationErrors?: boolean;
  disableColumnErrors?: boolean;
  allowMultipleOwners?: boolean;
  publicId?: string;
  objectTypes?: LoaderObjectType[];
  discardDatabaseChanges?: boolean;
}

export interface LoaderHistoryItem {
  command: LoadCommand;
  results: LoaderResults;
}

export interface LoaderHistoryApiResponse {
  history: LoaderHistoryItem[];
}
